<template>
  <div class="mx-6">
    <h2>Privacy Policy for Cody Eklov's Personal and Professional Website</h2>
    <p><strong>Effective Date: January 8, 2024</strong></p>

    <h4>Welcome to https://www.codyeklov.com</h4>
    <p>
      Welcome to https://www.codyeklov.com (the "Site"), the personal and
      professional website of Cody Eklov. This Privacy Policy outlines how your
      personal information is collected, used, and shared when you visit the
      Site. Cody Eklov is committed to ensuring that your privacy is protected
      in accordance with the General Data Protection Regulation (GDPR) and other
      relevant privacy laws.
    </p>

    <h4>1. Who We Are</h4>
    <p>
      The Site is owned and operated by Cody Eklov, a software development and
      IT professional. Our contact details are:
    </p>
    <ul>
      <li>Email: info@codyeklov.com</li>
      <li>Address: 402 Orchard Hills Dr., Norwalk, IA 50211</li>
    </ul>

    <h4>2. Information We Collect</h4>
    <p>When you visit the Site, we may collect the following information:</p>
    <ul>
      <li>
        <strong>Technical Data:</strong> Includes internet protocol (IP)
        address, your login data, browser type and version, time zone setting
        and location, browser plug-in types and versions, operating system and
        platform, and other technology on the devices you use to access the
        Site.
      </li>
      <li>
        <strong>Usage Data:</strong> Includes information about how you use our
        website, products, and services.
      </li>
      <li>
        <strong>Contact Data:</strong> If you choose to contact us through the
        Site, we may collect your email address and any other information you
        provide in your communication.
      </li>
    </ul>

    <h4>3. How We Use Your Information</h4>
    <p>We use your information in the following ways:</p>
    <ul>
      <li>
        To Operate and Maintain the Site: Including troubleshooting, data
        analysis, testing, system maintenance, support, reporting, and hosting
        of data.
      </li>
      <li>
        To Improve Our Site: By ensuring that content is presented in the most
        effective manner for you and your device.
      </li>
      <li>
        To Communicate with You: Responding to your queries, requests, or
        comments.
      </li>
    </ul>

    <h4>4. Sharing Your Information</h4>
    <p>
      We do not sell, distribute, or lease your personal information to third
      parties unless we have your permission or are required by law to do so.
    </p>

    <h4>5. Data Security</h4>
    <p>
      We are committed to ensuring that your information is secure. In order to
      prevent unauthorized access or disclosure, we have put in place suitable
      physical, electronic, and managerial procedures to safeguard and secure
      the information we collect online.
    </p>

    <h4>6. Your Rights Under GDPR</h4>
    <p>Under the GDPR, you have various rights including:</p>
    <ul>
      <li>
        <strong>Access:</strong> You have the right to request copies of your
        personal data.
      </li>
      <li>
        <strong>Rectification:</strong> You have the right to request correction
        of any information you believe is inaccurate.
      </li>
      <li>
        <strong>Erasure:</strong> You have the right to request erasure of your
        personal data under certain conditions.
      </li>
      <li>
        <strong>Restriction of Processing:</strong> You have the right to
        request the restriction of processing of your personal data.
      </li>
      <li>
        <strong>Data Portability:</strong> You have the right to request the
        transfer of your data to another organization, or directly to you.
      </li>
    </ul>
    <p>
      To exercise any of these rights, please contact us at info@codyeklov.com.
    </p>

    <h4>7. Changes to This Privacy Policy</h4>
    <p>
      We may update this Privacy Policy from time to time. We will notify you of
      any changes by posting the new Privacy Policy on this page.
    </p>

    <h4>8. Contact Us</h4>
    <p>
      For any questions or concerns regarding this Privacy Policy, please
      contact us at info@codyeklov.com or by mail at 402 Orchard Hills Dr.,
      Norwalk, IA 50211.
    </p>
  </div>
</template>